import { createSlice } from "@reduxjs/toolkit";

import dayjs from "dayjs";

const initialState = {
  loader: false,
  params: {
    brands: [],
    clients: [],
    products: [],
    countries: [],
    categories: [],
    tags: [],
    marketplaces: [],
    infringementTypes: [],
    channel: [],
    listingStatus: [],
    selectedListings: [],
    state: [],
    seller: "",
    value: "",
    start: dayjs().subtract(6, "month").startOf("month").valueOf(),
    end: dayjs().endOf("day").valueOf(),
    order: "desc",
    page: 0,
    limit: 10,
    sortBy: "created",
    field: "created",
    dateField: "Created",
    testPurchase: false,
    keyboardMode: false,
    filed: "all",
    assigned: [],
  },
  imageParams: {
    url: undefined,
    listing: undefined,
    search: undefined,
    order: "desc",
    page: 0,
    limit: 10,
    sortBy: "created",
    field: "created",
  },
  domainParams: {
    search: undefined,
    field: "name",
    start: dayjs().subtract(6, "month").startOf("month").valueOf(),
    end: dayjs().endOf("day").valueOf(),
    page: 0,
    limit: 10,
    dateField: "Created",
    sortBy: "name",
    complaintStatus: [],
  },
  filters: {
    dateRange: [null, null],
  },
  fetchListing: false,
  fetchComplaints: false,
  fetchSellers: false,
  fetchDomains: false,
  fetchInvestigations: false,
  fetchAdvertisements: false,
  listing: {},
  messages: [],
  printing: false,
};

/* General app store slice for holding non-API fetched or perhaps local store persistence */
export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setParams: (state, action) => {
      state.params = action.payload;
    },
    setBrand: (state, action) => {
      state.brands = action.payload;
    },
    setProduct: (state, action) => {
      state.products = action.payload;
    },
    setSeller: (state, action) => {
      state.seller = action.payload;
    },
    setSelectedListings: (state, action) => {
      state.selectedListings = action.payload;
    },
    setDateRange: (state, action) => {
      state.params.start = action.payload.start;
      state.params.end = action.payload.end;
    },
    setLoader: (state, _action) => {
      state.loader = false;
    },
    setStates: (state, action) => {
      state.states = action.payload;
    },
    setTestPurchase: (state, action) => {
      state.testPurchase = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    addMessage: (state, action) => {
      state.messages = [...state.messages, action.payload];
    },
    setListingFetch: (state, action) => {
      state.fetchListing = action.payload;
    },
    setListing: (state, action) => {
      state.listing = action.payload;
    },
    setComplaintsFetching: (state, action) => {
      state.fetchComplaints = action.payload;
    },
    setInvestigationsFetching: (state, action) => {
      state.fetchInvestigations = action.payload;
    },
    setSellersFetching: (state, action) => {
      state.fetchSellers = action.payload;
    },
    setAdvertisementsFetching: (state, action) => {
      state.fetchAdvertisements = action.payload;
    },
    setTestPurchasesFetching: (state, action) => {
      state.fetchInvestigations = action.payload;
    },
    setKeyboardMode: (state, action) => {
      state.keyboardMode = action.payload;
    },
    setImageParams: (state, action) => {
      state.imageParams = action.payload;
    },
    setDomainsFetching: (state, action) => {
      state.fetchDomains = action.payload;
    },
    setAssigned: (state, action) => {
      state.assigned = action.payload;
    },
    setPrinting: (state, action) => {
      state.printing = action.payload;
    },
    setDomainParams: (state, action) => {
      state.domainParams = action.payload;
    },
  },
});

export const {
  setParams,
  setBrand,
  setProduct,
  setSeller,
  setSelectedListings,
  setDateRange,
  setLoader,
  setStates,
  setTestPurchase,
  setMessages,
  addMessage,
  setListingFetch,
  setComplaintsFetching,
  setInvestigationsFetching,
  setSellersFetching,
  setListing,
  setKeyboardMode,
  setImageParams,
  setDomainsFetching,
  setTestPurchasesFetching,
  setAssigned,
  setPrinting,
  setAdvertisementsFetching,
  setDomainParams,
} = appSlice.actions;

export default appSlice.reducer;
